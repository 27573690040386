// Import UIkit components

// Base
@import "../uikit/components/variables.scss";
@import "../uikit/components/mixin.scss";
@import "../uikit/components/base.scss";

// Elements
@import "../uikit/components/link.scss";
@import "../uikit/components/heading.scss";
@import "../uikit/components/divider.scss";
@import "../uikit/components/list.scss";
// @import "../uikit/components/description-list.scss";
@import "../uikit/components/table.scss";
@import "../uikit/components/icon.scss";
@import "../uikit/components/form-range.scss";
@import "../uikit/components/form.scss"; // After: Icon, Form Range
@import "../uikit/components/button.scss";

// Layout
@import "../uikit/components/section.scss";
@import "../uikit/components/container.scss";
@import "../uikit/components/grid.scss";
// @import "../uikit/components/tile.scss";
@import "../uikit/components/card.scss";

// Common
@import "../uikit/components/close.scss"; // After: Icon
// @import "../uikit/components/spinner.scss"; // After: Icon
@import "../uikit/components/totop.scss"; // After: Icon
// @import "../uikit/components/marker.scss"; // After: Icon
@import "../uikit/components/alert.scss"; // After: Close
@import "../uikit/components/badge.scss";
@import "../uikit/components/label.scss";
@import "../uikit/components/overlay.scss"; // After: Icon
@import "../uikit/components/article.scss"; // After: Subnav
// @import "../uikit/components/comment.scss"; // After: Subnav
@import "../uikit/components/search.scss"; // After: Icon

// Navs
@import "../uikit/components/nav.scss";
@import "../uikit/components/navbar.scss"; // After: Card, Grid, Nav, Icon, Search
@import "../uikit/components/subnav.scss";
// @import "../uikit/components/breadcrumb.scss";
@import "../uikit/components/pagination.scss";
@import "../uikit/components/tab.scss";
@import "../uikit/components/slidenav.scss"; // After: Icon
@import "../uikit/components/dotnav.scss";
// @import "../uikit/components/thumbnav.scss";

// JavaScript
@import "../uikit/components/accordion.scss";
@import "../uikit/components/drop.scss"; // After: Card
@import "../uikit/components/dropdown.scss"; // After: Card
@import "../uikit/components/modal.scss"; // After: Close
@import "../uikit/components/lightbox.scss"; // After: Close
@import "../uikit/components/slideshow.scss";
@import "../uikit/components/slider.scss";
@import "../uikit/components/sticky.scss";
@import "../uikit/components/offcanvas.scss";
@import "../uikit/components/switcher.scss";
// @import "../uikit/components/leader.scss";
// Scrollspy
// Toggle
// Scroll

// Additional
// @import "../uikit/components/iconnav.scss";
// @import "../uikit/components/notification.scss";
// @import "../uikit/components/tooltip.scss";
// @import "../uikit/components/placeholder.scss";
// @import "../uikit/components/progress.scss";
// @import "../uikit/components/sortable.scss";
// @import "../uikit/components/countdown.scss";

// Utilities
@import "../uikit/components/animation.scss";
@import "../uikit/components/width.scss";
@import "../uikit/components/height.scss";
@import "../uikit/components/text.scss";
// @import "../uikit/components/column.scss";
@import "../uikit/components/cover.scss";
@import "../uikit/components/background.scss";
@import "../uikit/components/align.scss";
@import "../uikit/components/svg.scss";
@import "../uikit/components/utility.scss";
@import "../uikit/components/flex.scss"; // After: Utility
@import "../uikit/components/margin.scss";
@import "../uikit/components/padding.scss";
@import "../uikit/components/position.scss";
@import "../uikit/components/transition.scss";
@import "../uikit/components/visibility.scss";
@import "../uikit/components/inverse.scss";

// Need to be loaded last
@import "../uikit/components/print.scss";