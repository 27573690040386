pre.highlight {
    background-color: #F7F8FA;
    border: none;
    padding: $padding-padding * .8;
}

.highlight .hll { background-color: #ffffcc }
.highlight .c { font-style: italic } /* Comment */
.highlight .err { } /* Error */
.highlight .k { font-weight: bold } /* Keyword */
.highlight .cm { font-style: italic } /* Comment.Multiline */
.highlight .c1 { font-style: italic } /* Comment.Single */
.highlight .cs { font-style: italic } /* Comment.Special */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gh { font-weight: bold } /* Generic.Heading */
.highlight .gp { font-weight: bold } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { font-weight: bold } /* Generic.Subheading */
.highlight .kc { font-weight: bold } /* Keyword.Constant */
.highlight .kd { font-weight: bold } /* Keyword.Declaration */
.highlight .kn { font-weight: bold } /* Keyword.Namespace */
.highlight .kr { font-weight: bold } /* Keyword.Reserved */
.highlight .s { font-style: italic } /* Literal.String */
.highlight .nc { font-weight: bold } /* Name.Class */
.highlight .ni { font-weight: bold } /* Name.Entity */
.highlight .ne { font-weight: bold } /* Name.Exception */
.highlight .nn { font-weight: bold } /* Name.Namespace */
.highlight .nt { font-weight: bold } /* Name.Tag */
.highlight .ow { font-weight: bold } /* Operator.Word */
.highlight .sb { font-style: italic } /* Literal.String.Backtick */
.highlight .sc { font-style: italic } /* Literal.String.Char */
.highlight .sd { font-style: italic } /* Literal.String.Doc */
.highlight .s2 { font-style: italic } /* Literal.String.Double */
.highlight .se { font-weight: bold; font-style: italic } /* Literal.String.Escape */
.highlight .sh { font-style: italic } /* Literal.String.Heredoc */
.highlight .si { font-weight: bold; font-style: italic } /* Literal.String.Interpol */
.highlight .sx { font-style: italic } /* Literal.String.Other */
.highlight .sr { font-style: italic } /* Literal.String.Regex */
.highlight .s1 { font-style: italic } /* Literal.String.Single */
.highlight .ss { font-style: italic } /* Literal.String.Symbol */