button.sharer {
  border: none;
  border-radius: $border-rounded-border-radius;
  box-shadow: none;
  color: white;
  display: block;
  font-family: sans-serif;
  font-size: $global-small-font-size;
  outline: none;
  padding: 0 12px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.08);
  }
}
button[data-sharer="twitter"] {
  background-color: #00aced;
}
button[data-sharer="facebook"] {
  background-color: #3b5998;
}
button[data-sharer="linkedin"] {
  background-color: #007AB5;
}
button[data-sharer="googleplus"] {
  background-color: #DD4C39;
}
button[data-sharer="email"] {
  background-color: #444;
}
button[data-sharer="pinterest"] {
  background-color: #CB2029;
}
button[data-sharer="reddit"] {
  background-color: #CEE3F8;
  color: #335f89;
}
