// Global variables
$global-primary-background:                     #2BB381;
$global-secondary-background:                   #283648;
$global-success-background:                     #32d296;
$global-warning-background:                     #faa05a;
$global-danger-background:                      #ff6c5c;
$global-font-family:                            system-ui;
$global-link-color:                             $global-primary-background;
$global-link-hover-color:                       $global-primary-background;
$global-small-font-size:                        0.875rem;
$global-muted-color:                            #8e8e8e;
$global-xxlarge-font-size:                      2.5rem;
$global-2xlarge-font-size:                      2.5rem;
$global-xlarge-font-size:                       2rem;
$global-large-font-size:                        1.5rem;
$global-medium-font-size:                       1.25rem;
$xsmall-font-size:                              0.875rem;
                                
// Base variables
$base-heading-font-family:                      'Montserrat', system-ui, san-serif;
$base-heading-font-weight:                      600;
$base-heading-color:                            #000;
$base-h2-line-height:                           1.4;
// $base-link-hover-text-decoration:               none;
$base-body-color:                               #333;
$base-code-font-family:                         Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,serif;
$base-code-color:                               #6a6b71;
$base-code-font-size:                           $xsmall-font-size;
$base-pre-font-size:                            $xsmall-font-size;
$base-pre-line-height:                          1.65;
$base-pre-font-family:                          $base-code-font-family;
$base-pre-color:                                $base-code-color;
$border-light:                                  #edeff1;
// $border-rounded-border-radius:                  2px;
$text-lead-font-size:                           1.125rem;
$link-muted-hover-color:                        #222;
$link-text-hover-color:                         #555;
$overlay-primary-background:                    rgba(11, 14, 12, 0.7);
$base-em-color:                                 $global-danger-background;

$alert-padding:                                 8px;
$alert-danger-background:                       lighten(mix(white, $global-danger-background, 40%), 15%);

// Accordion variables
$accordion-item-margin-top:                     20px;
$accordion-title-font-size:                     1.1875rem;
$accordion-title-color:                         $global-link-color;
$accordion-title-hover-color:                   $global-link-color;
$accordion-content-margin-top:                  20px;
$accordion-icon-color:                          $global-primary-background;
$accordion-icon-background-color:               lighten( $global-primary-background, 57% );
$internal-accordion-open-image:                 "data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='#000' stroke-width='1.03' points='4 13 10 7 16 13' /%3E%3C/svg%3E";
$internal-accordion-close-image:                "data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='#000' stroke-width='1.03' points='16 7 10 13 4 7' /%3E%3C/svg%3E";

// Article variables
$article-title-font-size-m:                     2.625rem;
$article-title-line-height:                     1.4;
// $article-meta-font-size: 	                    0.8125rem;
$article-meta-line-height:                      1.6;

$container-xsmall-max-width:                    650px;
// Button variables
$button-font-weight:                            400;
$button-default-color:                          #222;
$button-default-hover-background:               $button-default-color;
$button-default-hover-color:                    #fff;
$button-default-active-background:              $button-default-color;
$button-default-active-color:                   #fff;
$button-default-border:                         $button-default-color;
$button-default-hover-border:                   $button-default-color;
$button-default-active-border:                  $button-default-color;
$button-warning-background:                     $global-warning-background;
$button-warning-hover-background:               darken($button-warning-background, 7%);
$button-success-background:                     $global-success-background;
$button-success-hover-background:               darken($button-success-background, 5%);
$inverse-button-primary-color:                  #222;
$inverse-button-primary-hover-color:            #000;

// Card variables
$card-small-body-padding-horizontal:            25px;
$card-small-body-padding-vertical:              25px;
$card-title-font-size:                          1.25rem;
$card-default-title-color:                      $base-heading-color;
$card-default-color:                            $base-body-color;

// Heading variables
$heading-small-font-size-m:                     3rem; 
$heading-divider-border-width:                  1px;
$heading-divider-border:                        #d0d5de;

// Logo variables
$logo-font-size:                                1.125rem;
$logo-color:                                    #000;
$logo-hover-color:                              #000;
$inverse-logo-color:                            #fff;
$inverse-logo-hover-color:                      #fff;

// Navigation variables
$nav-primary-item-font-size:                    $global-large-font-size;
$navbar-background:                             #fff;
$navbar-nav-item-color:                         #181818;
$navbar-nav-item-hover-color:                   $global-primary-background;
$navbar-nav-item-active-color:                  $global-primary-background;
$navbar-nav-item-font-size:                     15px;
// $navbar-nav-item-text-transform:                none;
$navbar-toggle-color:                           #181818;
$navbar-toggle-hover-color:                     $global-primary-background;
$navbar-dropdown-nav-item-color:                $base-body-color;
$offcanvas-bar-color-mode:                      dark;
$offcanvas-bar-background:                      #fff;
$subnav-item-font-size:                         15px;
$subnav-item-color:                             $base-body-color;
$subnav-item-hover-color:                       $global-primary-background;
$subnav-item-active-color:                      $global-primary-background;

$inverse-navbar-nav-item-color:                 #fff;

$dropdown-nav-item-color:                       $base-body-color;
$dropdown-nav-item-hover-color:                 $global-primary-background;

// $nav-item-padding-vertical:                     5px;
$nav-header-margin-top:                         10px;
$nav-sublist-item-padding-vertical:             5px;
$nav-default-item-color:                        $base-body-color;

$nav-default-sublist-item-color:                $base-body-color;
$nav-default-sublist-item-hover-color:          $global-primary-background;
$nav-default-sublist-item-active-color:         $global-primary-background;

$nav-primary-font-size:                         1.125rem;
$nav-primary-item-color:                        $base-body-color;
$nav-primary-item-hover-color:                  $global-primary-background;
// Search variables
$search-default-background:                     #fff;
$search-default-focus-background:               #fff;
$inverse-search-default-background:             rgba(0, 0, 0, 0.3);
$inverse-search-default-focus-background:       rgba(0, 0, 0, 0.5);

// Section variables
$section-success-background:                    $global-success-background;
$section-success-color-mode:                    light;
$section-danger-background:                     $global-danger-background;
$section-danger-color-mode:                     light;
$section-large-padding-vertical-m:              120px;

// Doc navigation
$docs-sidebar-width:                            260px;
$docs-sidebar-width-l:                          360px;